<template>
  <div class='warp-box'>
    <div class='d-flex flex-wrap  py-4'>
      <!-- <v-card width="365px" class="rounded-lg" flat
        ><v-img
          class="white--text align-end"
          gradient="to bottom, #B0CAFF, #FFFFFF"
          ><v-card-title class="justify-center"
            ><v-img
              lazy-src="../../../assets/img/icon_kaba.png"
              max-height="50"
              max-width="50"
              src="../../../assets/img/icon_kaba.png"
            ></v-img
            >{{ EnumdictForm.kaba }}</v-card-title
          ></v-img
        >
        <v-card-actions style="display: block">
          <div class="height_ren">
            <div class="guitai">
              <div class="renwu">选择柜台:</div>
              <div class="group_">
                <v-chip-group
                  v-model="selectCounter"
                  class="kaba-warp"
                  active-class="kaba-active"
                  @change="getValue($event, '01')"
                >
                  <v-chip
                    v-for="(item, i) in counterList"
                    :key="i"
                    class="my-0"
                    label
                    outlined
                    text-color="#616D84"
                    :disabled="kabajin"
                    >{{ item.counterCode }}</v-chip
                  >
                </v-chip-group>
              </div>
            </div>
            <div class="d-flex guitai">
              <div class="renwu">任务名称:</div>
              <div class="group_">
                <v-chip-group
                  v-model="selectcodes"
                  class="kaba-warp"
                  active-class="kaba-active"
                  @change="getValue($event, '05')"
                >
                  <v-chip
                    v-for="(item, i) in kabaListList"
                    :key="i"
                    :disabled="mcjin"
                    class="my-0"
                    label
                    outlined
                    text-color="#616D84"
                    >{{ item.taskName }}</v-chip
                  >
                </v-chip-group>
              </div>
            </div>
          </div>

          <div v-if="kababut === true" id="but" class="but">
            <k-primary-button
              v-if="
                counterbutton === 'start' ||
                selectCounter === null ||
                counterList.length == 0
              "
              v-show="hasPermission(['task.data.api.start'])"
              :class="counterFlg ? '' : 'mt-10'"
              color="primary"
              width="60%"
              :disabled="
                counterList.length == 0 ||
                selectCounter === null ||
                selectCounter === undefined
              "
              :loading="btnFlag1"
              text="开始"
              @click="start('01')"
            ></k-primary-button>
            <k-table-bluegrey-btn
              v-if="counterbutton === 'end'"
              v-show="hasPermission(['task.data.api.end'])"
              :class="counterFlg ? '' : 'mt-10'"
              color="primary"
              width="60%"
              text="结束"
              :loading="btnFlag1"
              :disabled="selectCounter === null || selectCounter === undefined"
              @click="end('01')"
            ></k-table-bluegrey-btn>
            <k-table-primary-btn
              v-if="counterbutton === 'doing'"
              :class="counterFlg ? '' : 'mt-10'"
              color="primary"
              width="60%"
              :disabled="kabaing === true"
              text="执行中"
            ></k-table-primary-btn>
          </div>
          <div v-if="mcbut === true" id="but" class="but">
            <k-primary-button
              v-if="
                selectbutton === 'start' ||
                selectcodes === null ||
                kabaListList.length == 0
              "
              v-show="hasPermission(['task.data.api.start'])"
              :class="counterFlg ? '' : 'mt-10'"
              color="primary"
              width="60%"
              :disabled="
                kabaListList.length == 0 ||
                selectcodes === null ||
                selectcodes === undefined
              "
              :loading="btnFlag5"
              text="开始"
              @click="start('05')"
            ></k-primary-button>
            <k-table-bluegrey-btn
              v-if="selectbutton === 'end'"
              v-show="hasPermission(['task.data.api.end'])"
              :class="counterFlg ? '' : 'mt-10'"
              color="primary"
              width="60%"
              :disabled="selectcodes === null || selectcodes === undefined"
              text="结束"
              :loading="btnFlag5"
              @click="end('05')"
            ></k-table-bluegrey-btn>
            <k-table-primary-btn
              v-if="selectbutton === 'doing'"
              :class="counterFlg ? '' : 'mt-10'"
              color="primary"
              width="60%"
              :disabled="renwuing === true"
              text="执行中"
            ></k-table-primary-btn>
          </div>
        </v-card-actions>
      </v-card> -->

      <v-card class='rounded-lg' flat width='365px'
      >
        <v-img
          class='white--text align-end'
          gradient='to bottom, #BFEEF5, #FFFFFF'
        >
          <v-card-title class='justify-center'
          >
            <v-img
              lazy-src='../../../assets/img/icon_chaku.png'
              max-height='50'
              max-width='50'
              src='../../../assets/img/icon_chaku.png'
            ></v-img
            >
            {{ EnumdictForm.room_insp }}
          </v-card-title
          >
        </v-img
        >
        <v-card-actions style='display: block'>
          <div class='height_ren'>
            <div class='guitai'>
              <div class='renwu'>任务名称:</div>
              <div class='group_'>
                <v-chip-group
                  v-model='selectCheck'
                  active-class='caku-active'
                  class='caku-warp'
                  @change="getValue($event, '02')"
                >
                  <v-chip
                    v-for='(item, i) in checkList'
                    :key='i'
                    class='my-0'
                    :disabled='item.endFlag==="01"'
                    label
                    outlined
                    text-color='#616D84'
                  >{{ item.taskName }}
                  </v-chip
                  >
                </v-chip-group>
              </div>
            </div>
          </div>

          <div id='but' class='but'>
            <k-Secondary-btn
              v-if="
                checkbutton === 'start' ||
                selectCheck === null ||
                checkList.length === 0
              "
              v-show="hasPermission(['task.data.api.start'])"
              :disabled='
                checkList.length === 0 ||
                selectCheck === null ||
                selectCheck === undefined
              '
              :loading='btnFlag2'
              class='mt-10'
              text='开始'
              width='60%'
              @click="start('02')"
            ></k-Secondary-btn>
            <k-table-bluegrey-btn
              v-if="checkbutton === 'end'"
              v-show="hasPermission(['task.data.api.end'])"
              :disabled='selectCheck === null || selectCheck === undefined'
              :loading='btnFlag2'
              class='mt-10'
              text='结束'
              width='60%'
              @click="end('02')"
            ></k-table-bluegrey-btn>
            <k-table-primary-btn
              v-if="checkbutton === 'doing'"
              :class="counterFlg ? '' : 'mt-10'"
              :disabled='chakuing === true'
              color='primary'
              text='执行中'
              width='60%'
            ></k-table-primary-btn>
          </div>
        </v-card-actions>
      </v-card>

      <v-card class='rounded-lg' flat style='margin-left:30px' width='365px'
      >
        <v-img
          class='white--text align-end'
          gradient='to bottom, #EDE8FF, #FFFFFF'
        >
          <v-card-title class='justify-center'
          >
            <v-img
              lazy-src='../../../assets/img/icon_qingji.png'
              max-height='50'
              max-width='50'
              src='../../../assets/img/icon_qingji.png'
            ></v-img
            >
            {{ EnumdictForm.room_atmclr }}
          </v-card-title
          >
        </v-img
        >
        <!--    清机    -->
        <v-card-actions style='display: block'>
          <div class='height_ren'>
            <div class='guitai'>
              <div class='renwu'>任务名称:</div>
              <div class='group_'>
                <v-chip-group
                  v-model='selectClean'
                  active-class='qj-active'
                  class='qj-warp'
                  @change="getValue($event, '03')"
                >

                  <v-chip
                    v-for='(item, i) in cleanList'
                    :key='i'
                    :disabled='item.endFlag==="01"'
                    class='my-0'
                    label
                    outlined
                    text-color='#616D84'
                  >{{ item.taskName }}
                  </v-chip
                  >
                </v-chip-group>
              </div>
            </div>
          </div>

          <div id='but' class='but'>
            <k-Table-Purple-btn
              v-if="
                selectClean === null ||
                cleanbutton === 'start' ||
                cleanList.length === 0
              "
              v-show="hasPermission(['task.data.api.start'])"
              :disabled='
                cleanList.length === 0 ||
                selectClean === null ||
                selectClean === undefined
              '
              :loading='btnFlag3'
              class='mt-10'
              color='purple'
              text='开始'
              width='60%'
              @click="start('03')"
            ></k-Table-Purple-btn
            >
            <k-table-bluegrey-btn
              v-if="cleanbutton === 'end'"
              v-show="hasPermission(['task.data.api.end'])"
              :disabled='selectClean === null || selectClean === undefined'
              :loading='btnFlag3'
              class='mt-10'
              color='purple'
              text='结束'
              width='60%'
              @click="end('03')"
            ></k-table-bluegrey-btn>
            <k-table-primary-btn
              v-if="cleanbutton === 'doing'"
              :class="counterFlg ? '' : 'mt-10'"
              :disabled='qingjiing === true'
              color='primary'
              text='执行中'
              width='60%'
            ></k-table-primary-btn>
          </div>
        </v-card-actions
        >
      </v-card>
      <!-- <v-card width="365px" class="rounded-lg" flat
        ><v-img
          class="white--text align-end"
          gradient="to bottom, #FCDFB5, #FFFFFF"
          ><v-card-title class="justify-center"
            ><v-img
              lazy-src="../../../assets/img/icon_chenhui.png"
              max-height="50"
              max-width="50"
              src="../../../assets/img/icon_chenhui.png"
            ></v-img
            >{{ EnumdictForm.meeting }}</v-card-title
          ></v-img
        >
        <v-card-actions style="display: block">
          <div class="height_ren">
            <div class="guitai">
              <div class="renwu">任务名称:</div>
              <div class="group_">
                <v-chip-group
                  v-model="selectMeeting"
                  class="ch-warp"
                  active-class="ch-active"
                  @change="getValue($event, '04')"
                >
                  <v-chip
                    v-for="(item, i) in meetingList"
                    :key="i"
                    class="my-0"
                    outlined
                    text-color="#616D84"
                    label
                    >{{ item.taskName }}</v-chip
                  >
                </v-chip-group>
              </div>
            </div>
          </div>

          <div id="but" class="but">
            <k-table-warning-btn
              v-if="
                selectMeeting === null ||
                meetingbutton == 'start' ||
                meetingList.length == 0
              "
              v-show="hasPermission(['task.data.api.start'])"
              min-width="60%"
              text="开始"
              :loading="btnFlag4"
              :disabled="
                meetingList.length == 0 ||
                selectMeeting === null ||
                selectMeeting === undefined
              "
              @click="start('04')"
            ></k-table-warning-btn>
            <k-table-bluegrey-btn
              v-if="meetingbutton == 'end'"
              v-show="hasPermission(['task.data.api.end'])"
              min-width="60%"
              text="结束"
              :loading="btnFlag4"
              :disabled="selectMeeting === null || selectMeeting === undefined"
              @click="end('04')"
            ></k-table-bluegrey-btn>
            <k-table-primary-btn
              v-if="meetingbutton == 'doing'"
              :class="counterFlg ? '' : 'mt-10'"
              color="primary"
              width="60%"
              :disabled="chenhuiing === true"
              text="执行中"
            ></k-table-primary-btn></div></v-card-actions
      ></v-card> -->
    </div>
    <k-crud-page :show-top='false' class='mt-4'>
      <template #resultlist>
        <k-crud-table
          :headers='columns'
          :items='cruddataMX.dataList'
          :paginationshow='false'
          :total='cruddataMX.pagination.total'
          @pageChanged='
            (page) => {
              crudPaginationMX().change(page);
            }
          '
        >
          <template #top>
            <v-toolbar flat>
              <div class='text-h5'>操作记录</div>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>
          <template #violationFlag='{ item }'>
            <div v-if="item.violationFlag === '01'">合规</div>
            <div v-if="item.violationFlag === ''"></div>
            <div v-if="item.violationFlag === '02'">不合规</div>
          </template>

          <template #statusCode='{ item }'>
            <div
              v-if="item.statusCode === '01'"
              style='
                display: flex;
                align-items: center;
                justify-content: center;
              '
            >
              <span style='color: #aaaaaa; font-size: 30px'>·</span> 完成
            </div>
            <div
              v-else
              style='
                display: flex;
                align-items: center;
                justify-content: center;
              '
            >
              <span style='color: #01b750; font-size: 30px'>·</span> 执行中
            </div>
          </template>

          <template #actions='{ item }'>
            <!-- 已完成的任务显示详情按钮 -->
            <k-table-blue-btn
              v-if="item.statusCode == '01'"
              text='详情'
              @click='
                crudActionMX().details(
                  crud,
                  item.recordUuid,
                  getVideos(item.recordUuid),
                  formatData
                )
              '
            >
            </k-table-blue-btn>
            <!-- 已完成结果不合格可重新执行的任务显示重新执行按钮 -->
            <k-primary-button
              v-if="item.violationFlag == '02' && item.reExecuteFlg === '01' && item.description.indexOf('过期') == -1"
              v-show="hasPermission(['task.data.api.restart'])"
              :loading='item.nextflag'
              :mall='true'
              style='margin-left: 5px'
              text='重新执行'
              @click='reStart(item.recordUuid, item)'
            ></k-primary-button>
            <!-- 重新执行进行中的任务显示结束按钮 -->
            <k-table-bluegrey-btn
              v-if="item.endFlag == '01' && item.statusCode == '02'"
              v-show="hasPermission(['task.data.api.end'])"
              :loading='item.endflag'
              :mall='true'
              style='margin-left: 5px'
              text='结束'
              @click='endTaskInList(item.taskUuid, item.counterUuid, item)'
            ></k-table-bluegrey-btn>
          </template>
        </k-crud-table>
        <k-crud-dialog
          v-model='crud.switch'
          :actionshow='actionShow'
          :title='crud.title'
          class='diang'
          maxwidth='800px'
          @close='
            () => {
              crudActionMX().closeEditingPage(crud);
            }
          '
          @save='
            () => {
              crudActionMX().saveItem();
            }
          '
        >
          <template #dialogContent>
            <v-form :ref='crud.form.ref'>
              <div>
                <div>
                  <v-row>
                    <v-col cols='4'>
                      <div>时间：{{ cruddataMX.data.operationStartTime }}</div>
                    </v-col>
                    <v-col cols='4'>
                      <div>机构：{{ cruddataMX.data.orgName }}</div>
                    </v-col>
                    <v-col cols='2'>
                      <div>柜台：{{ cruddataMX.data.counterCode }}</div>
                    </v-col>
                    <v-col cols='2'>
                      <div>操作人：{{ accountState.userName }}</div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols='4'
                    >
                      <div>金额：{{ cruddataMX.data.amount }}</div>
                    </v-col
                    >
                    <v-col cols='4'>
                      <div>
                        结果：
                        <span
                          :style='activation(cruddataMX.data.violationFlag)'
                        >
                          {{ cruddataMX.data.violationFlag }}
                        </span>
                      </div>
                    </v-col>
                    <v-col cols='4'>
                      <div>结果描述：{{ cruddataMX.data.description }}</div>
                    </v-col>
                    <v-col></v-col>
                  </v-row>
                </div>

                <div v-if='shipin' class='ml-4 form_im'>
                  <kvideo v-if='videos' v-model='playerOptions'></kvideo>
                  <v-img
                    v-if='images'
                    id='img_form'
                    :src='playerOptions'
                    class=''
                    height='200'
                  ></v-img>
                </div>
              </div>
            </v-form>
          </template>
        </k-crud-dialog>
      </template>
    </k-crud-page>
  </div>
</template>
<script>
import moment from 'moment';
import httpConfig from '@/config/http.config';
import KPrimaryButton from '../../../components/koi/button/KPrimaryButton.vue';
import Kvideo from '../../../components/koi/button/Kvideo.vue';

export default {
  components: { KPrimaryButton, Kvideo },
  data() {
    return {
      commonUrl: httpConfig.imgUrl,
      counterbutton: '',
      checkbutton: '',
      cleanbutton: '',
      meetingbutton: '',
      selectbutton: '',
      btnFlag5: false,
      btnFlag4: false,
      btnFlag3: false,
      btnFlag2: false,
      btnFlag1: false,
      endflag: false,
      nextflag: false,
      shipin: false,
      videos: false, //判断视频显示站位不占位
      images: false,
      playerOptions: '',
      statusCode: false,
      EnumdictForm: {
        room_insp: '行领导查库',
        kaba: '卡把',
        room_atmclr: '清机',
        meeting: '晨会',
      },
      // 柜台选择
      selectCounter: null,

      //柜台名称选择
      selectcodes: null,
      selectCheck: null,
      // 行领导查库任务执行状态
      checkStatus: null,
      // 清机任务选择
      selectClean: null,
      // 清机任务执行状态
      cleanStatus: null,
      // 晨会任务选择
      selectMeeting: null,
      // 晨会任务执行状态
      meetingStatus: null,
      //卡把任务状态
      selectStatus: null,
      // 柜台列表
      counterList: [],
      // 行领导查库任务列表
      checkList: [],
      // 清机任务列表
      cleanList: [],
      // 晨会任务列表
      meetingList: [],
      //卡把任务名称
      kabaListList: [],
      // 当前柜台是否存在卡把任务flg
      counterFlg: false,
      actionShow: false,
      dates: [],
      taskTypeList: [],
      statusList: [],
      resultList: [],
      crud: {
        name: '任务详情',
        title: '', // 在Mixin中被修改
        switch: false,
        uploading: false,
        loading: false,
        form: {
          ref: 'taskListModalForm',
        },
        default: {
          actualCompleteTime: '',
          orgName: '',
          location: '',
          executorName: '',
          statusName: '',
          violationFlagName: '',
          statusCode: '',
          remark: '',
          url: '',
          previewUrl: '',
          taskUuid: '',
          violationFlag: '',
          description: '',
        },
      },
      kaba: '',
      wackName: [],
      columns: [
        {
          text: '序号',
          value: 'indexNum',
          sortable: false,
          align: 'center',
          class: 'vtableH',
        },
        {
          text: '项目类型',
          value: 'analysisItem',
          sortable: false,
          align: 'center',
        },
        {
          text: '临时任务',
          value: 'taskType',
          sortable: false,
          align: 'center',
        },
        {
          text: '开始时间',
          value: 'startTime',
          sortable: false,
          align: 'center',
        },
        {
          text: '完成时间',
          value: 'completeTime',
          sortable: false,
          align: 'center',
        },
        {
          text: '执行状态',
          value: 'statusCode',
          sortable: false,
          align: 'center',
        },
        {
          text: '执行结果',
          value: 'violationFlag',
          sortable: false,
          align: 'center',
        },
        {
          text: '执行结果描述',
          value: 'description',
          sortable: false,
          align: 'center',
        },
        {
          text: '操作',
          value: 'actions',
          sortable: false,
          align: 'center',
        },
      ],
      // 测试机构
      orgTreeList: [],
      selectedGantaList: [],
      kabaflag: false,
      chakuflag: false,
      qingjiflag: false,
      chenhuiflag: false,
      chakuing: true, //行领导查库结束状态
      kabaing: true, //卡把结束状态
      checautoEnd: null, //行领导查库是否为手动
      counterautoEnd: null, //卡把是否为手动
      cleanautoEnd: null, //清机是否为手动
      qingjiing: true, //清机结束状态
      meetingautoEnd: null, //晨会是否为手动
      chenhuiing: true, //晨会结束状态
      autoEnd: '', //卡把任务名称是否为手动
      renwuflag: false, //卡把任务名称flag
      renwuing: true, //卡把任务名称结束状态
      mcjin: false, //卡把名称按钮隐藏
      kabajin: false, //卡把柜台按钮隐藏
      kababut: false, //卡把柜台隐藏
      mcbut: true, //卡把名称隐藏
      gengxin: false, //判断卡把任务名称是否第二次刷新
      uuid: '',
      kaishi: false, //判断开始完事结束按钮
      jieshu: false, //判断开始完事结束按钮
      kaishiqingji: false, //判断开始完事结束按钮
      jieshuqingji: false, //判断开始完事结束按钮
      kaishichenhui: false, //判断开始完事结束按钮
      jieshuchenhui: false, //判断开始完事结束按钮
      kaishiguitai: false, //判断开始完事结束按钮
      jieshuguitai: false, //判断开始完事结束按钮
      kaishimingcheng: false, //判断开始完事结束按钮
      jieshumingcheng: false, //判断开始完事结束按钮
      tit: '',
      taskUuid: '',
    };
  },
  computed: {
    activation() {
      return (violationFlag) => {
        // 使用JavaScript闭包，进行传值操作
        if (violationFlag === '01' || violationFlag === '合规') {
          return { color: 'green' };
        } else if (violationFlag === '02' || violationFlag === '不合规') {
          return { color: 'red' };
        }
      };
    },
  },

  created() {
    this.getEnumdict();

    this.crudInitMX(this.crud, {
      list: this.$api.mine.mine.getoperate,
      formalist: this.formalist,
      details: (params) => {
        const recordUuid = params.uuid;
        return this.$api.mine.mine.getrecordInfo({ recordUuid: recordUuid });
      },
    });
    this.uuid = this.$store.state.loginStore.account.uuid;
  },
  methods: {
    //列表修改
    formalist(res) {
      const accUuid = this.$store.state.loginStore.account.roleVos[0].uuid;
      res.forEach((item) => {
        if (accUuid === item.accUuid) {
          item.status = '0';
        }
      });
      return res;
    },
    //表单修改
    formatData(resData) {
      resData.operationStartTime = moment(resData.operationStartTime).format(
        'YYYY-MM-DD hh:mm:ss',
      );
      resData.operationEndTime = moment(resData.operationEndTime).format(
        'YYYY-MM-DD hh:mm:ss',
      );
      if (resData.violationFlag === '02') {
        resData.violationFlag = '不合规';
      }
      if (resData.violationFlag === '01') {
        resData.violationFlag = '合规';
      }
      return resData;
    },
    getVideos(recordUuid) {
      this.$api.mine.mine.getVideo({ recordUuid: recordUuid }).then((res) => {
        if (res.code === 200) {
          if (res.data[0] !== '') {
            this.shipin = true;
            let str = res.data[0];
            str = str.substring(str.length - 3);
            if (str === 'jpg' || str === 'png') {
              this.images = true;
              this.videos = false;
              this.playerOptions = this.commonUrl + res.data[0];
            } else {
              this.videos = true;
              this.images = false;
              this.playerOptions = this.commonUrl + res.data[0];
            }
          } else {
            this.shipin = false;
          }
        }
      });
    },
    //获取title
    getEnumdict() {
      this.$api.mine.mine.getEnumdict().then((res) => {
        if (res.code == 200) {
          // 卡把柜台列表，默认选中第一个
          const EnumdictList = res.data[0].AnalysisItemsEnum;
          EnumdictList.map((item) => {
            if (item.code === 'room_insp') {
              this.EnumdictForm.room_insp = item.msg;
              this.wackName.push(item.code);
            }
            if (item.code === 'kaba') {
              this.EnumdictForm.kaba = item.msg;
              this.kaba = item.code;
              this.wackName.push(item.code);
            }
            if (item.code === 'room_atmclr') {
              this.EnumdictForm.room_atmclr = item.msg;
              this.wackName.push(item.code);
            }
            if (item.code === 'meeting') {
              this.EnumdictForm.meeting = item.msg;
              this.wackName.push(item.code);
            }
          });
          this.getcounter(this.kaba);
          this.getclean();
          this.getmonmeet();
          this.getinsp();
          this.getKaba();
        }
      });
    },
    //获取卡把柜台数据
    getcounter(val) {
      this.$api.mine.mine.getcounter({ code: val }).then((res) => {
        if (res.code == 200) {
          // // 卡把柜台列表，默认选中第一个
          if (res.data?.length) {
            this.counterList = res.data;
            if (this.kaishiguitai === true) {
              if (this.selectCounter !== null) {
                this.counterbutton =
                  this.counterList[this.selectCounter].button;
              }
            }
            if (this.jieshuguitai === true) {
              this.selectCounter = null;
              this.counterbutton = '';
            }
          } else {
            if (this.jieshuguitai === true) {
              this.selectCounter = null;
              this.counterbutton = '';
              this.counterList = [];
            }
            this.selectCounter = null;
            this.counterbutton = '';
            this.counterList = [];
          }
        }
      });
    },
    //获取清机
    getclean() {
      this.$api.mine.mine.getclean().then((res) => {
        if (res.code == 200) {
          if (res.data?.length) {
            // 清机任务列表
            this.cleanList = res.data;

            if (this.kaishiqingji === true) {
              if (this.selectClean !== null) {
                this.cleanbutton = this.cleanList[this.selectClean].button;
              }
            }
            if (this.jieshuqingji === true) {
              this.selectClean = null;
              this.cleanbutton = '';
            }
          } else {
            if (this.jieshuqingji === true) {
              this.selectClean = null;
              this.cleanbutton = '';
              this.cleanList = [];
            }

            this.selectClean = null;
            this.cleanbutton = '';
            this.cleanList = [];
          }
        } else {
          this.selectClean = null;
          this.cleanbutton = '';
          this.cleanList = [];
        }
      });
    },
    //获取晨会
    getmonmeet() {
      this.$api.mine.mine.getmonmeet().then((res) => {
        if (res.code == 200) {
          if (res.data?.length) {
            // 晨会任务列表
            this.meetingList = res.data;
            if (this.kaishichenhui === true) {
              if (this.selectMeeting !== null) {
                this.meetingbutton =
                  this.meetingList[this.selectMeeting].button;
              }
            }
            if (this.jieshuchenhui === true) {
              this.selectMeeting = null;
              this.meetingbutton = '';
            }
          } else {
            if (this.jieshuchenhui === true) {
              this.selectMeeting = null;
              this.meetingbutton = '';
              this.meetingList = [];
            }
            this.selectMeeting = null;
            this.meetingbutton = '';
            this.meetingList = [];
          }
        } else {
          this.selectMeeting = null;
          this.meetingbutton = '';
          this.meetingList = [];
        }
      });
    },
    // 获取行领导查库
    getinsp() {
      this.$api.mine.mine.getinsp().then((res) => {
        if (res.code == 200) {
          // 行领导查库任务列表
          if (res.data?.length) {
            this.checkList = res.data;
            if (this.kaishi === true) {
              if (this.selectCheck !== null) {
                this.checkbutton = this.checkList[this.selectCheck].button;
              }
            }
            if (this.jieshu === true) {
              this.selectCheck = null;
              this.checkbutton = '';
            }
          } else {
            if (this.jieshu === true) {
              this.selectCheck = null;
              this.checkbutton = '';
              this.checkList = [];
            }
            this.selectCheck = null;
            this.checkbutton = '';
            this.checkList = [];
          }
        } else {
          this.selectCheck = null;
          this.checkbutton = '';
          this.checkList = [];
        }
      });
    },

    // 点击chip获取值
    getValue(i, type) {
      // 卡把柜台选择
      if (type == '01') {
        if (i !== undefined) {
          this.kababut = true;
          this.mcbut = false;
          this.mcjin = true;
          this.kabajin = false;
          this.selectCounter = i;
          this.counterbutton = this.counterList[this.selectCounter].button;
        } else {
          // this.jinyong = false;
          this.kababut = false;
          this.mcbut = true;
          this.mcjin = false;
          this.kabajin = false;
        }
      }
      // 行领导查库任务选择
      if (type == '02') {
        if (i !== undefined) {
          this.selectCheck = i;
          this.checkbutton = this.checkList[this.selectCheck].button;
        }
      }
      //清机任务选择
      if (type === '03') {
        if (i !== undefined) {
          this.selectClean = i;
          this.cleanbutton = this.cleanList[this.selectClean].button;
        }
      }
      //晨会任务选择
      if (type === '04') {
        if (i !== undefined) {
          this.selectMeeting = i;
          this.meetingbutton = this.meetingList[this.selectMeeting].button;
        }
      }
      //卡把名称选择
      if (type === '05') {
        if (i !== undefined) {
          this.kababut = false;
          this.mcbut = true;
          this.mcjin = false;
          this.kabajin = true;
          this.selectcodes = i;
          this.selectbutton = this.kabaListList[this.selectcodes].button;
        } else {
          this.mcjin = false;
          this.kabajin = false;
          this.kababut = true;
          this.mcbut = false;
        }
      }
    },
    // 根据柜台获取当前柜台是否存在卡把任务
    getKaba(location) {
      this.$api.mine.mine.getKaba({ counterUuid: location }).then((res) => {
        if (res.code == 200) {
          if (res.data?.length) {
            this.kabaListList = res.data;

            if (this.kaishimingcheng === true) {
              if (this.kabaListList?.length) {
                // console.log(this.selectcodes, 'this.selectcodes');
                // this.kabaListList.map((item, i) => {
                //   this.selectcodes = i;
                //   this.selectbutton = item.button;
                //   // this.kabaListList[this.selectcodes].button;
                this.mcbut = true;
                this.kababut = false;
                // });
                this.selectbutton = this.kabaListList[this.selectcodes].button;
              }

              //     this.kabaListList.map((item, i) => {
              //   if (i === 0) {
              //     this.selectcodes = i;
              //     this.selectbutton = this.kabaListList[this.selectcodes].button;
              //     this.mcbut = true;
              //     this.kababut = false;
              //   }
              // });
            }
            if (this.jieshumingcheng === true) {
              this.selectcodes = null;
              this.selectbutton = '';
              if (this.kabaListList?.length) {
                this.kabaListList.map((item, i) => {
                  if (i === 0) {
                    this.selectcodes = i;
                    this.selectbutton = item.button;
                    // this.selectbutton =
                    //   this.kabaListList[this.selectcodes].button;
                    this.mcbut = true;
                    this.kababut = false;
                    // this.kabajin = false;
                  }
                });
              }
            } else if (
              this.kaishimingcheng === false &&
              this.jieshumingcheng === false
            ) {
              this.kabaListList.map((item, i) => {
                if (i === 0) {
                  this.selectcodes = i;
                  this.selectbutton =
                    this.kabaListList[this.selectcodes].button;
                  this.mcbut = true;
                  this.kababut = false;
                }
              });
            }
          }
        } else if (res.code === 0) {
          this.kabajin = false;
          this.kabaListList = res.data;
          this.mcbut = false;
          this.kababut = true;
          this.selectcodes = null;
          this.selectbutton = '';
          if (this.jieshumingcheng === true) {
            this.kabajin = false;
            this.mcbut = false;
            this.kababut = true;
            this.selectcodes = null;
            this.selectbutton = '';
            this.kabaListList === [];
          }
        }
      });
    },
    // 点击开始按钮
    start(type) {
      let taskUuid;
      // eslint-disable-next-line no-debugger
      // console.log(+type);
      this[`btnFlag${+type}`] = true;
      let itemCode

      console.log(itemCode,this.cleanList[this.selectClean])
      if (type == '01') {
        if (this.selectCounter === null || this.selectCounter === undefined) {
          this.$alert.warning('需选中一个任务');
          return;
        } else {
          const counterUuid = this.counterList[this.selectCounter].counterUuid;

          this.kaishiguitai = true;
          this.jieshuguitai = false;
          this.$api.mine.mine
            .startTask({ counterUuid: counterUuid })
            .then((res) => {
              if (res.code == 200) {
                this.$alert.success(res.message);
                this.crudConditionsMX().resetPage();
                this.getcounter(this.kaba);
                this[`btnFlag${+type}`] = false;
              } else {
                this[`btnFlag${+type}`] = false;
                this.$alert.warning(res.message);
              }
            })
            .catch(() => {
              this[`btnFlag${+type}`] = false;
            });
        }
      } else {
        // 行领导查库任务选择
        if (type == '02') {
          if (this.selectCheck === null || this.selectCheck === undefined) {
            this.$alert.warning('需选中一个任务');
            return;
          } else {
            taskUuid = this.checkList[this.selectCheck].taskUuid;
            itemCode = this.checkList[this.selectCheck] ? this.checkList[this.selectCheck].itemCode : undefined;
            this.kaishi = true;
            this.jieshu = false;
          }
        }
        // 清机任务选择
        if (type == '03') {
          if (this.selectClean === null || this.selectClean === undefined) {
            this.$alert.warning('需选中一个任务');
            return;
          } else {
            taskUuid = this.cleanList[this.selectClean].taskUuid;
            itemCode = this.cleanList[this.selectClean] ? this.cleanList[this.selectClean].itemCode : undefined;
            this.kaishiqingji = true;
            this.jieshuqingji = false;
          }
        }

        // // 晨会任务选择
        if (type == '04') {
          if (this.selectMeeting === null || this.selectMeeting === undefined) {
            this.$alert.warning('需选中一个任务');
            return;
          } else {
            taskUuid = this.meetingList[this.selectMeeting].taskUuid;
            this.kaishichenhui = true;
            this.jieshuchenhui = false;
          }
        }
        // // 卡把任务名称
        if (type == '05') {
          if (this.selectcodes === null || this.selectcodes === undefined) {
            this.$alert.warning('需选中一个任务');
            return;
          } else {
            taskUuid = this.kabaListList[this.selectcodes].taskUuid;
            this.gengxin = true;
            this.kaishimingcheng = true;
            this.jieshumingcheng = false;
          }
        }

        this.$api.mine.mine
          // .startTask({ uuid: uuid, type: type, flg: this.counterFlg })
          .startTask({ taskUuid: taskUuid, itemCode })
          .then((res) => {
            if (res.code === 200) {
              this.$alert.success(res.message);
              this.crudConditionsMX().resetPage();
              if (type === '02') {
                this.getinsp();
              }
              if (type === '03') {
                this.getclean();
              }
              if (type === '04') {
                this.getmonmeet();
              }
              if (type === '05') {
                this.getKaba();
              }
              this[`btnFlag${+type}`] = false;
            } else {
              this.$alert.warning(res.message);
            }
          })
          .catch(() => {
            this[`btnFlag${+type}`] = false;
          });
      }
    },
    // 点击结束按钮
    end(type) {
      let taskUuid;
      this[`btnFlag${+type}`] = true;
      let itemCode;
      // 卡把柜台选择
      if (type == '01') {
        if (this.selectCounter === null || this.selectCounter === undefined) {
          this.$alert.warning('需选中一个任务');
          return;
        } else {
          const counterUuid = this.counterList[this.selectCounter].counterUuid;

          this.kaishiguitai = false;
          this.jieshuguitai = true;
          this.$api.mine.mine
            .endTask({ counterUuid: counterUuid })
            .then((res) => {
              if (res.code == 200) {
                if (res.data?.[0] !== '1') {
                  this.$alert.warning(`任务异常断开，已重新执行`);
                } else {
                  this.$alert.success(res.message);
                }
                this.crudConditionsMX().resetPage();
                this.getcounter(this.kaba);
                this[`btnFlag${+type}`] = false;
              } else {
                this.$alert.error(res.message);
              }
            })
            .catch(() => {
              this[`btnFlag${+type}`] = false;
            });
        }
      } else {
        // 行领导查库任务选择
        if (type == '02') {
          if (this.selectCheck === null || this.selectCheck === undefined) {
            this.$alert.warning('需选中一个任务');
            return;
          } else {
            taskUuid = this.checkList[this.selectCheck].taskUuid;
            itemCode = this.checkList[this.selectCheck] ? this.checkList[this.selectCheck].itemCode : undefined;
            this.kaishi = false;
            this.jieshu = true;
          }
        }
        // 清机任务选择
        if (type == '03') {
          if (this.selectClean === null || this.selectClean === undefined) {
            this.$alert.warning('需选中一个任务');
            return;
          } else {
            taskUuid = this.cleanList[this.selectClean].taskUuid;
            itemCode = this.cleanList[this.selectClean] ? this.cleanList[this.selectClean].itemCode : undefined;
            this.kaishiqingji = false;
            this.jieshuqingji = true;
          }
        }
        // 晨会任务选择
        if (type == '04') {
          if (this.selectMeeting === null || this.selectMeeting === undefined) {
            this.$alert.warning('需选中一个任务');
            return;
          } else {
            taskUuid = this.meetingList[this.selectMeeting].taskUuid;
            this.kaishichenhui = false;
            this.jieshuchenhui = true;
          }
        }
        if (type == '05') {
          if (this.selectcodes === null || this.selectcodes === undefined) {
            this.$alert.warning('需选中一个任务');
            return;
          } else {
            taskUuid = this.kabaListList[this.selectcodes].taskUuid;
            this.kaishimingcheng = false;
            this.jieshumingcheng = true;
          }
        }
        // // 卡把任务名称
        this.$api.mine.mine
          .endTask({ taskUuid: taskUuid, itemCode })
          .then((res) => {
            if (res.code == 200) {
              this.$alert.success(res.message);
              if (type === '02') {
                this.getinsp();
              }
              if (type === '03') {
                this.getclean();
              }
              if (type === '04') {
                this.getmonmeet();
              }
              if (type === '05') {
                this.getKaba();
              }
              this.gengxin = false;
              this.crudConditionsMX().resetPage();
              this[`btnFlag${+type}`] = false;
            } else {
              this.$alert.error(res.message);
            }
          })
          .catch(() => {
            this[`btnFlag${+type}`] = false;
          });
      }
    },
    // 点击重新执行按钮
    reStart(recordUuid, item) {
      if (item) {
        this.$set(item, 'nextflag', true);
        this.$api.mine.mine
          .reStartTask(recordUuid)
          .then((res) => {
            if (res.code == 200) {
              this.$set(item, 'nextflag', false);
              this.$alert.success(res.message);
              this.crudConditionsMX().resetPage();
              // this.getKaba();
              // this.getcounter(this.kaba);
              // this.getmonmeet();
              // this.getclean();
              // this.getinsp();
            } else {
              this.$set(item, 'nextflag', false);
              this.$alert.error(res.message);
            }
          })
          .catch(() => {
            this.$set(item, 'nextflag', false);
          });
      }
    },
    // 点击列表中结束按钮
    endTaskInList(id, key, item) {
      if (item) {
        this.$set(item, 'endflag', true);
        if (id !== null) {
          const taskUuid = id;
          this.$api.mine.mine
            .endTask({ taskUuid: taskUuid })
            .then((res) => {
              if (res.code == 200) {
                if (res.data?.[0] !== '1') {
                  this.$alert.warning(`任务异常断开，已重新执行`);
                } else {
                  this.$alert.success(res.message);
                }
                this.$set(item, 'endflag', false);
                this.$alert.success(res.message);
                this.crudConditionsMX().resetPage();
                // this.getmonmeet();
                // this.getclean();
                // this.getinsp();
              } else {
                this.$set(item, 'endflag', false);
                this.$alert.error(res.message);
              }
            })
            .catch(() => {
              this.$set(item, 'endflag', false);
            });
        } else {
          const counterUuid = key;
          this.$api.mine.mine
            .endTask({ counterUuid: counterUuid })
            .then((res) => {
              if (res.code == 200) {
                this.$set(item, 'endflag', false);
                if (res.data?.[0] !== '1') {
                  this.$alert.warning(`任务异常断开，已重新执行`);
                } else {
                  this.$alert.success(res.message);
                }
                this.crudConditionsMX().resetPage();
                // this.getmonmeet();
                // this.getclean();
                // this.getinsp();
              } else {
                this.$set(item, 'endflag', false);
                this.$alert.error(res.message);
              }
            })
            .catch(() => {
              this.$set(item, 'endflag', false);
            });
        }
      }
    },
  },
};
</script>
<style>
.diang .v-dialog {
  border-radius: 20px !important;
}

#but .v-btn {
  height: 36px !important;
  min-width: 60% !important;
}

.group_ .v-slide-group__wrapper {
  width: 180px;
}

.group_ .v-slide-group__content {
  display: block !important;
}

.group_ .v-icon.v-icon {
  font-size: 15px !important;
}

.group_ .v-chip .v-chip__content {
  justify-content: center;
}

.group_ .v-slide-group__next,
.v-slide-group__prev {
  min-width: 5% !important;
  flex: 0 !important;
}

/* .group_ .v-item-group {
  width: 80% !important;
} */
#chaku .v-chip__content {
  color: #0ec1de !important;
}

/*#kaba .v-chip__content {*/
/*  color: #2e71fe !important;*/
/*}*/
#qingji .v-chip__content {
  color: #8b68ff !important;
}

#chenhui .v-chip__content {
  color: #ff9b07 !important;
}

/*.theme--light.v-chip:not(.v-chip--active) {*/
/*  background: #f9f9f9 !important;*/
/*  border: 1px solid #c7cede !important;*/
/*}*/
</style>
<style lang='scss' scoped>
::v-deep .v-slide-group__prev--disabled {
  display: none !important;
}

.warp-box .kaba-warp .kaba-active {
  color: #2e71fe !important;
  border-color: #8db0fb !important;
  background-color: #f1f5ff !important;

  &.v-chip--outlined.v-chip--active:before {
    opacity: 0 !important;
  }
}

.warp-box .caku-warp .caku-active {
  color: #0ec1de !important;
  border-color: #0ec1de !important;
  background-color: #e7fafe !important;

  &.v-chip--outlined.v-chip--active:before {
    opacity: 0 !important;
  }
}

.warp-box .qj-warp .qj-active {
  color: #8b68ff !important;
  border-color: #9489fe !important;
  background-color: #f2efff !important;

  &.v-chip--outlined.v-chip--active:before {
    opacity: 0 !important;
  }
}

.warp-box .ch-warp .ch-active {
  color: #ff9b07 !important;
  border-color: #ffbe5e !important;
  background-color: #fff9ef !important;

  &.v-chip--outlined.v-chip--active:before {
    opacity: 0 !important;
  }
}

.height_ren {
  height: 80px;
}

.v-application .deep-purple--text.text--accent-4 {
  color: #2e71fe !important;
}

.group_ {
  width: 70% !important;
}

.renwu {
  width: 27%;
  font-size: 14px;
  font-weight: 500;
  text-align: right;
  padding-right: 5px;
}

.guitai {
  display: flex;
  align-items: center;
  padding-left: 10px !important;
}

.v-application .white--text {
  color: #000 !important;
}

.ml-4 {
  background: rgba(255, 255, 255, 0.39);
  box-shadow: 0px 6px 28px rgba(178, 184, 194, 0.31);
  opacity: 1;
  border-radius: 14px;
}

.theme--light.v-sheet--outlined {
  border: none !important;
}

.v-application .justify-center {
  justify-content: left !important;
}

::v-deep .theme--light.v-chip:focus::before {
  opacity: 0;
  //background-color: transparent;
}

.but {
  display: flex;
  justify-content: center !important;
  height: 60px;
  align-items: center;
}

.v-application .mt-10 {
  margin-top: 0px !important;
}
</style>
